import "ag-grid-community/dist/styles/ag-grid.min.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import paginationMixin from "@/Mixins/paginationMixin";
import PaginationComponent from "../../../Global-Component/pagination.vue";
// import deletedUser from "./user-delete.vue";

import { _ } from "vue-underscore";

export default {
  // components: {
  // 	'user-delete':deletedUser,
  // },
  name: "UserList",
  data() {
    return {
      showModal: false,
      userList: [],
      selected: "",
      searchText: "",
      showLoader: false,
      selectedId: 0,
      selectedRows: [],
      sortColumn: "name",
      sortDirection: "asc",
      backUrl: "/users",
      paginate: {
        page_count: 1,
        selected: 1,
        per_page: 50,
        current_page: 1,
        last_page: 0,
      },
      recordTakeOptions: [25, 50, 100, 200],
    };
  },
  mixins: [paginationMixin],
  components: {
    PaginationComponent,
  },
  computed: {
    selectAllList() {
      if (this.userList.length > 0) {
        return (
          _.where(this.userList, {
            isSelected: true,
          }).length === this.userList.length
        );
      }
      return false;
    },
    hasParminentDeleteUserPermission() {
      const menuList = this.$store.state.menuList;
      const currentRoute = this.$route.path;
      let hasPermission = false;
      menuList.forEach((item) => {
        if (
          item.menu_link === currentRoute &&
          _.where(item.permissions, { name: "User Trash" }).length > 0
        ) {
          hasPermission = true;
        } else if (item.child && item.child.length > 0) {
          item.child.forEach((element) => {
            if (
              element.menu_link === currentRoute &&
              _.where(element.permissions, { name: "User Trash" }).length > 0
            ) {
              hasPermission = true;
            } else if (element.child && element.child.length > 0) {
              element.child.forEach((subElement) => {
                if (
                  subElement.menu_link === currentRoute &&
                  _.where(subElement.permissions, { name: "User Trash" })
                    .length > 0
                ) {
                  hasPermission = true;
                }
              });
            }
          });
        }
      });
      return hasPermission;
    },
    hasRestorUserPermission() {
      const menuList = this.$store.state.menuList;
      const currentRoute = this.$route.path;
      let hasPermission = false;
      menuList.forEach((item) => {
        if (
          item.menu_link === currentRoute &&
          _.where(item.permissions, { name: "User Restore" }).length > 0
        ) {
          hasPermission = true;
        } else if (item.child && item.child.length > 0) {
          item.child.forEach((element) => {
            if (
              element.menu_link === currentRoute &&
              _.where(element.permissions, { name: "User Restore" }).length > 0
            ) {
              hasPermission = true;
            } else if (element.child && element.child.length > 0) {
              element.child.forEach((subElement) => {
                if (
                  subElement.menu_link === currentRoute &&
                  _.where(subElement.permissions, { name: "User Restore" })
                    .length > 0
                ) {
                  hasPermission = true;
                }
              });
            }
          });
        }
      });
      return hasPermission;
    },
  },
  methods: {
    permanentDelete(id) {
      let _vm = this;

      if (this.selectedId > 0) {
        id = [this.selectedId];
      } else {
        this.userList.forEach((element) => {
          if (element.isSelected == true) {
            id.push(element.id);
          }
        });
      }
      this.modal7;
      _vm.axios.post("/user-permanent-delete/" + id).then(function (response) {
        _vm.getAll();
        _vm.selectedId = 0;
      });
    },

    deletedUser(user, event) {
      let _vm = this;
      let _msg = "Are you sure you want to Active this User?";
      this.$dialog
        .confirm(_msg)
        .then(function () {
          _vm.axios
            .post("/user-restore/" + user.id)
            .then(function () {
              _vm.getAll();
            })
            .catch(function () {
              document.getElementById("user-" + user.id);
            });
        })
        .catch(function () {
          document.getElementById("user-" + user.id);
        });
    },

    getNextPage(page) {
      this.$refs.paginate.selected = page;
      this.paginate.current_page = page;
      this.getAll();
    },
    getQueryString() {
      let queryString =
        "?page=" +
        this.paginate.current_page +
        "&take=" +
        this.paginate.per_page +
        "&sort=" +
        this.sortColumn +
        "," +
        this.sortDirection;
      queryString +=
        this.searchText.length > 0 ? "&search=" + this.searchText : "";
      return queryString;
    },
    getNextPage(page) {
      this.paginate.current_page = page;
      this.getAll();
    },
    updateRecordTake(newRecordTake) {
      this.paginate.per_page = newRecordTake;
      this.getAll();
    },
    getPageCount(dividend, divider) {
      let page = Math.floor(dividend / divider);
      return dividend % divider === 0 ? page : page + 1;
    },
    applySort(columnName) {
      if (this.sortColumn === columnName) {
        this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
      } else {
        this.sortColumn = columnName;
        this.sortDirection = "asc";
      }
      this.getAll();
    },

    checkUncheckedList(event) {
      this.userList.forEach((element) => {
        element.isSelected = event.target.checked;
      });
    },
    search() {
      this.getAll();
    },

    getAll() {
      const _vm = this;
      let queryString = this.getQueryString();
      _vm.showLoader = true;
      this.axios
        .get("/user-trash" + queryString)
        .then(function (response) {
          _vm.showLoader = false;
          let data = response.data.data.data;
          _vm.paginate.last_page = response.data.data.last_page;
          for (let index = 0; index < data.length; index++) {
            data[index]["isSelected"] = false;
          }
          _vm.userList = data;
          _vm.paginate.page_count = _vm.getPageCount(
            response.data.data.total,
            response.data.data.per_page
          );
        })
        .catch(function () {});
    },
    handleCheck(e) {
      if (e.target.id === "customCheck-1") {
        if (e.target.checked) {
          this.selectedRows = this.userList.map((item) => "checkbox" + item.id);
          this.userList.forEach((element) => {
            element.isSelected = e.target.checked;
          });
        } else {
          this.selectedRows = [];
          this.userList.forEach((element) => {
            element.isSelected = false;
          });
        }
      } else {
        if (e.target.checked) {
          this.selectedRows.push(e.target.id);
        } else {
          this.selectedRows = this.selectedRows.filter((item) => {
            if (item !== e.target.id) {
              return item;
            }
          });
        }
      }
    },
  },
  mounted() {
    this.getAll();
  },
};
